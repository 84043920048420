/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-grid-system';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Local UI components
import PromotionHeader from '../PromotionHeader';
import FilmCard from '../FilmCard';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Schedule({ title, theme, promotion }) {
  const { products, categories } = promotion;

  /* ********************************** HOOKS ********************************* */
  const [maxGridCount, setMaxGridCount] = useState();
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);

  // Localization
  const { t } = useTranslation('Common');
  const productsContainer = document.getElementById('products-container');
  useEffect(() => {
    // get max columns number that can be displayed on the screen
    setMaxGridCount(
      Math.floor(
        Math.min(
          productsContainer?.clientWidth /
            Math.max(200, productsContainer?.clientWidth / 6),
          6,
        ),
      ),
    );
  }, [productsContainer?.clientWidth]);

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className={`schedule fluid ${theme}`} fluid>
      <PromotionHeader title={title} categories={categories} />
      <Container className="products-container fluid" id="products-container">
        {(products.length > maxGridCount * 2 && !seeMoreClicked
          ? products.slice(0, maxGridCount * 2 - 1)
          : products
        ).map((p) => (
          <FilmCard key={p.uniqueId} product={p} />
        ))}

        {products.length > maxGridCount * 2 && !seeMoreClicked && (
          // eslint-disable-next-line
          <div
            role="button"
            tabIndex={0}
            className="see-more"
            onClick={() => {
              setSeeMoreClicked(true);
            }}
          >
            {t('viewMore')}
          </div>
        )}
      </Container>
    </Container>
  );
}

Schedule.propTypes = {
  promotion: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({ uniqueId: PropTypes.string }).isRequired,
    ).isRequired,
    categories: PropTypes.shape({
      color: PropTypes.string,
      key: PropTypes.string,
      name: PropTypes.string,
      productType: PropTypes.string,
    }),
  }).isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
};

Schedule.defaultProps = {
  theme: 'light',
};

export default Schedule;
