/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from 'react-grid-system';

// Local UI components
import PromotionHeader from '../PromotionHeader';

// Helpers
import { productQualifierByCategory } from '../../Helpers/entities';

// Style
import './index.scss';
import ImageBox from '../ImageBox';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SpecialEvent({ id, cover, category, title, theme, mainProduct }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className={`special-event fluid ${theme}`} fluid>
      <PromotionHeader title={title} category={category} id={id} />
      <Link
        to={`/product/${
          productQualifierByCategory(mainProduct.category.key).key
        }/${mainProduct.uniqueId}`}
      >
        <Container className="main-layout fluid">
          <ImageBox image={cover} alt="special event thumbnail" />
        </Container>
      </Link>
    </Container>
  );
}

SpecialEvent.propTypes = {
  id: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  mainProduct: PropTypes.shape({
    uniqueId: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      color: PropTypes.string,
    }),
  }).isRequired,
};

SpecialEvent.defaultProps = {
  theme: 'light',
};

export default SpecialEvent;
