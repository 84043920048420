/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// UI lib comoponents
import { Container } from 'react-grid-system';
import Modal from '../Modal/Modal';
import ImageBox from '../ImageBox';

// Context
import { DateContext } from '../../../context/DateContext';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function FilmCard({ product }) {
  /* ********************************** HOOKS ********************************* */

  // State
  const [openModal, setOpenModal] = useState(false);

  // Localization
  const { t } = useTranslation('Common');

  /* ***************************** LOCAL VARIABLES **************************** */
  const context = useContext(DateContext);
  const {
    cover,
    title,
    uniqueId: productUniqueId,
    category,
    previewExternalVideo,
  } = product;

  /* ***************************** RENDER HELPERS ***************************** */

  /**
   * Renders the trailer modal
   */
  function getTrailerModal() {
    if (previewExternalVideo) {
      return (
        <Modal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          title={`${t('cards.film.trailer')} : ${title || ''}`}
        >
          <iframe
            title="YoutubeVideo"
            style={{ height: '55vh', width: '100%', border: 'none' }}
            src={previewExternalVideo.replace('watch?v=', 'embed/')}
          />
        </Modal>
      );
    }
    return null;
  }

  /**
   * Renders CTA button for opening the trailer modal
   */
  function getOpenTrailerModalCta() {
    if (previewExternalVideo) {
      return (
        <button
          type="button"
          className="trailers"
          onClick={() => setOpenModal(true)}
        >
          &#9658;
          <span> </span>
          {category.key === 'cinema'
            ? t('cards.film.trailers')
            : t('cards.video')}
        </button>
      );
    }
    return null;
  }

  function renderImage() {
    return (
      <Container
        className="parent-wrapper"
        onClick={() => {
          navigate(`/product/${category.productType}/${productUniqueId}`, {
            state: { date: context.selectedDate },
          });
        }}
      >
        <div className="child-wrapper">
          <ImageBox image={cover} alt="film" />
        </div>
      </Container>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="film-card fluid">
      {getTrailerModal()}
      <div className="product-cover">{renderImage()}</div>
      <div className="actions">
        <Link to={`/product/${category.productType}/${productUniqueId}`}>
          <button type="button">{t('cards.film.sessions')}</button>
        </Link>
        {getOpenTrailerModalCta()}
      </div>
    </Container>
  );
}

FilmCard.propTypes = {
  product: PropTypes.shape({
    uniqueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    previewExternalVideo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
};

export default FilmCard;
