/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Ui lib components
import { Container } from 'react-grid-system';

// Local UI components
import Badge from '../Badge';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                           Category Separator Component                     */
/* -------------------------------------------------------------------------- */

function PromotionHeader({ title, categories }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="promotion-header flex items-center ">
      <Container className="content flex fluid">
        <p className="title">{title}</p>
        <div className="badges-container">
          {categories.length === 1 ? (
            <div className="badge-item">
              <Badge category={categories[0]} />
            </div>
          ) : null}
        </div>
      </Container>
    </Container>
  );
}

PromotionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

PromotionHeader.defaultProps = {
  categories: [],
};

export default PromotionHeader;
