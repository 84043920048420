/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Ui lib components
import { Container, useScreenClass } from 'react-grid-system';
import Carousel from 'react-elastic-carousel';

// Local UI components
import ImageBox from '../ImageBox';
import PromotionHeader from '../PromotionHeader';

// Helpers
import { productQualifierByCategory } from '../../Helpers/entities';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                          Single Evening Card Component                     */
/* -------------------------------------------------------------------------- */

function Festival({ theme, title, products, categories }) {
  const screenClass = useScreenClass();

  /* ******************************** CONSTANTS ******************************* */
  const carouselSettings = {
    showArrows:
      // eslint-disable-next-line operator-linebreak
      (['lg', 'xl', 'xxl'].includes(screenClass) && products.length > 7) ||
      (['xs', 'sm', 'md'].includes(screenClass) && products.length > 1),
    pagination: false,
    itemsToScroll: 1,
    disableArrowsOnEnd: true,
  };

  function renderImage(image) {
    return (
      <div className="parent-wrapper">
        <div className="child-wrapper">
          <ImageBox image={image} alt="festival-product" />
        </div>
      </div>
    );
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className={`festival ${theme} fluid`} fluid>
      <PromotionHeader title={title} categories={categories} />
      <Container className="main-layout fluid">
        <Carousel {...carouselSettings}>
          {(products || []).map((p) => (
            <a
              key={p.uniqueId}
              href={`/product/${
                productQualifierByCategory(p.category.key).key
              }/${p.uniqueId}`}
            >
              {renderImage(p.cover)}
            </a>
          ))}
        </Carousel>
      </Container>
    </Container>
  );
}

Festival.propTypes = {
  id: PropTypes.string.isRequired,
  categories: PropTypes.oneOf(['theatre', 'cinema']).isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

Festival.defaultProps = {
  theme: 'light',
};

export default Festival;
