/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import Spinner from '../../../shared/UIKit/Spinner';
import Banner from '../Banner';
import Schedule from '../../../shared/UIKit/Schedule';
import Festival from '../../../shared/UIKit/Festival';
import SpecialEvent from '../../../shared/UIKit/SpecialEvent';
import TheaterPieces from '../../../shared/UIKit/TheaterPieces';
import Events from '../../../shared/UIKit/Events';

// Context
import { DateContext } from '../../../context/DateContext';

// Helpers & utils
import { allCategories } from '../../../shared/Helpers/entities';
import { SUCCESSFUL_RESPONSE_CODE } from '../../../shared/constants';
import { getImageByAvailableFormat } from '../../../shared/Helpers/assets';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Promotions() {
  /* ******************************** CONSTANTS ******************************* */

  const today = format(new Date(), 'yyyy-MM-dd');

  /* ********************************** HOOKS ********************************* */
  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [promotions, setPromotions] = useState([]);
  const [banners, setBanners] = useState([]);

  // Context
  const context = useContext(DateContext);

  // Localization
  const { t } = useTranslation('HomePage');

  // Callbacks
  const fetchHome = useCallback(async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/promotion/web/${
          context?.selectedDate ?? today
        }`,
      );
      if (result.status === SUCCESSFUL_RESPONSE_CODE) {
        const { data } = result;
        if (data.length === 0) context?.pushEmptyDay(context?.selectedDate);

        if (context?.isEmptyDay(context?.selectedDate)) {
          context?.popEmptyDate?.(context?.selectedDate);
        }
        setPromotions(data.filter((p) => p.template !== 'banner'));
        setBanners(data.filter((p) => p.template === 'banner'));
      } else {
        setError(
          "Une erreur s'est produite. Merci de réessayer ultérieurement.",
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Une erreur s'est produite. Merci de réessayer ultérieurement.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const renderPromotion = (promotion, index) => {
    switch (promotion.template) {
      case 'schedule':
        if (promotion.categories?.length === 1) {
          switch (promotion.categories[0].key) {
            case allCategories()[0].name:
              return (
                <Schedule
                  theme={index % 2 === 0 ? 'light' : 'dark'}
                  title={promotion.title}
                  promotion={promotion}
                />
              );
            case allCategories()[1].name:
              return (
                <TheaterPieces
                  theme={index % 2 === 0 ? 'light' : 'dark'}
                  title={promotion.title}
                  promotion={promotion}
                />
              );
            case allCategories()[2].name:
            case allCategories()[3].name:
              return (
                <Events
                  key={promotion.uniqueId}
                  id={promotion.uniqueId}
                  theme={index % 2 === 0 ? 'light' : 'dark'}
                  title={promotion.title}
                  categories={promotions.categories}
                  products={promotion.products.map((product) => ({
                    image: `${
                      process.env.GATSBY_STORAGE_URL
                    }${getImageByAvailableFormat(product.cover)}`,
                    id: product.uniqueId,
                    title: product.title,
                    location: product.featuredEvent.venue.name,
                    date: product.featuredEvent.start,
                    category: product.category,
                  }))}
                />
              );
            default:
              return (
                <Schedule
                  theme={index % 2 === 0 ? 'light' : 'dark'}
                  title={promotion.title}
                  promotion={promotion}
                />
              );
          }
        } else {
          return (
            <Schedule
              theme={index % 2 === 0 ? 'light' : 'dark'}
              title={promotion.title}
              promotion={promotion}
            />
          );
        }
      case 'festival':
        return (
          <Festival
            key={index}
            id={index}
            theme={index % 2 === 0 ? 'light' : 'dark'}
            title={promotion.title}
            products={promotion.products}
            categories={promotion.categories}
          />
        );
      case 'specialEvent':
        return (
          <SpecialEvent
            key={promotion.uniqueId}
            id={promotion.uniqueId}
            category={
              promotion.categories.length === 1
                ? promotion.categories[0].name
                : null
            }
            title={promotion.title}
            cover={promotion.cover}
            mainProduct={promotion.products[0]}
          />
        );
      default:
        return <></>;
    }
  };

  // Side Effects
  useEffect(() => {
    fetchHome();
    return () => {
      setLoading(false);
      setError('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.selectedDate]);

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid promotions" fluid>
      {!loading ? (
        <>
          {!error ? (
            <>
              {banners.length > 0 ? <Banner promotions={banners} /> : null}
              {promotions.length > 0 ? (
                promotions.map((promotion, index) => {
                  if (
                    promotion.products?.length > 0 ||
                    promotion.type === 'specialEvent'
                  ) {
                    return renderPromotion(promotion, index);
                  }
                  return null;
                })
              ) : (
                <div className="empty-placeholder">
                  <h3>{t('noPomotionsPlaceholder')}</h3>
                </div>
              )}
            </>
          ) : (
            <h2 className="error">{error}</h2>
          )}
        </>
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
    </Container>
  );
}

export default Promotions;
