/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// UI Lib
import { Container } from 'react-grid-system';

// Local UI components
import PromotionHeader from '../PromotionHeader';
import EventCard from '../EventCard';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Events({ id, categories, theme, title, products }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className={`events ${theme} fluid`} fluid>
      <PromotionHeader title={title} categories={categories} id={id} />
      <Container className="products-container main-layout fluid">
        {(products || []).map((product) => (
          <EventCard
            id={product.id}
            key={product.id}
            category={product.category}
            image={product.image}
            title={product.title}
            date={new Date(product.date)}
            location={product.location}
            time={format(new Date(product.date), 'HH:mm', {
              locale: fr,
            })}
          />
        ))}
      </Container>
    </Container>
  );
}

Events.propTypes = {
  id: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string,
        key: PropTypes.string,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

Events.defaultProps = {
  theme: 'light',
  categories: [],
};

export default Events;
