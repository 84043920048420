/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Helpers & utilss
import { getImageByAvailableFormat } from '../../Helpers/assets';

/* -------------------------------------------------------------------------- */
/*                                    Conponent                               */
/* -------------------------------------------------------------------------- */

function ImageBox({ image, alt, ...props }) {
  /* ******************************** CONSTANTS ******************************* */
  const { GATSBY_STORAGE_URL } = process.env;

  /* ***************************** LOCAL VARIABLES **************************** */

  let imagePath;

  if (typeof image === 'string') {
    imagePath = `${GATSBY_STORAGE_URL}${image}`;
  } else {
    imagePath = !image
      ? '/no-image.jpeg'
      : `${GATSBY_STORAGE_URL}${getImageByAvailableFormat(image)}`;
  }

  /* ******************************** RENDERING ******************************* */
  return <img src={`${imagePath}`} alt={alt} {...props} />;
}

ImageBox.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  alt: PropTypes.string.isRequired,
};

export default ImageBox;
