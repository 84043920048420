/* eslint-disable indent */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container } from 'react-grid-system';
import Carousel from 'react-elastic-carousel';

// Local UI components
import Badge from '../../../shared/UIKit/Badge';

// Constants
import { IMAGE_FORMATS } from '../../../shared/constants';

// Helpers & utils
import { getImageByAvailableFormat } from '../../../shared/Helpers/assets';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Banner({ promotions }) {
  /* ******************************** CONSTANTS ******************************* */

  const { GATSBY_STORAGE_URL } = process.env;

  const carouselSettings = {
    itemsToShow: 1,
    itemsToScroll: 1,
    enableAutoPlay: true,
    showArrows: false,
  };

  /* ******************************** CALLBACKS ******************************* */

  /**
   * For a given product cover returns the available format
   * @param {Object} cover
   * @returns {string} product cover url source
   */
  function getProductCover(cover) {
    const coverPath = !cover
      ? '/no-image.jpeg'
      : `${GATSBY_STORAGE_URL}${getImageByAvailableFormat(
          cover,
          IMAGE_FORMATS.ORIGINAL,
        )}`;
    return `${coverPath}`;
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="banner fluid" fluid>
      <Carousel
        renderPagination={promotions.length === 1 ? () => <div /> : undefined}
        enableSwipe={promotions.length > 1}
        {...carouselSettings}
      >
        {promotions.map((p) => (
          <div
            key={p.u}
            className="banner-item"
            style={{
              background: `center / cover no-repeat url(${getProductCover(
                p.cover,
              )})`,
            }}
          >
            <div className="banner-title">
              <p>{p.title}</p>
              {p.categories.length === 1 ? (
                <Badge category={p.categories[0]} />
              ) : null}
            </div>
          </div>
        ))}
      </Carousel>
    </Container>
  );
}

Banner.propTypes = {
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      cover: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          key: PropTypes.string,
          color: PropTypes.string,
          productType: PropTypes.string,
        }),
      ),
    }),
  ),
};

Banner.defaultProps = {
  promotions: [],
};

export default Banner;
