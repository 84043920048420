/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { navigate } from 'gatsby';

// Ui lib components
import { Container } from 'react-grid-system';

// context
import { DateContext } from '../../../context/DateContext';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                          Single Play Card Component                     */
/* -------------------------------------------------------------------------- */

function PlayCard({ product }) {
  /* ******************************** CONSTANTS ******************************* */

  const { GATSBY_STORAGE_URL } = process.env;

  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation('Common');

  /* ***************************** LOCAL VARIABLES **************************** */

  const { uniqueId, title, category, cover, featuredEvent } = product;

  const date = new Date(featuredEvent.start);
  const time = format(new Date(featuredEvent.start), 'HH:mm', { locale: fr });
  const backgroundImageUrlSource = `${GATSBY_STORAGE_URL}${cover?.original}`;

  const context = useContext(DateContext);

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="play-card fluid">
      <div
        className="category-img"
        style={{ backgroundImage: `url(${backgroundImageUrlSource})` }}
        loading="lazy"
        alt="category image"
      />
      <Container className="details flex flex-column fluid">
        <p className="title">{title}</p>
        {/* eslint-disable indent */}
        <span className="description">
          {date
            ? format(new Date(date), 'd LLL Y', {
                locale: fr,
              })
            : t('notSpecified')}
        </span>
        <span className="description">{featuredEvent.venue.name}</span>
        <span className="description">{date ? time : t('notSpecified')}</span>
        {/* eslint-enable indent */}
      </Container>
      <Container
        className="view-more"
        onClick={() => {
          navigate(`/product/${category.productType}/${uniqueId}`, {
            state: { date: context.selectedDate },
          });
        }}
      >
        {t('view')}
      </Container>
    </Container>
  );
}

PlayCard.propTypes = {
  product: PropTypes.shape({
    uniqueId: PropTypes.string,
    title: PropTypes.string,
    cover: PropTypes.shape({
      original: PropTypes.string,
    }),
    category: PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      productType: PropTypes.string,
    }),
    image: PropTypes.string,
    featuredEvent: PropTypes.shape({
      start: PropTypes.string,
      venue: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default PlayCard;
