/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI Lib components
import { Container } from 'react-grid-system';

// Local UI components
import PromotionHeader from '../PromotionHeader';
import PlayCard from '../PlayCard';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function TheaterPieces({ theme, promotion }) {
  /* ***************************** LOCAL VARIABLES **************************** */
  const { title, products, categories } = promotion;

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className={`theatre-pieces ${theme} fluid`} fluid>
      <PromotionHeader title={title} categories={categories} />
      <Container className="products-container fluid">
        {products.map((p) => (
          <PlayCard key={p.uniqueId} product={p} />
        ))}
      </Container>
    </Container>
  );
}

TheaterPieces.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  promotion: PropTypes.shape({
    title: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        featuredEvent: PropTypes.shape({
          start: PropTypes.string,
          venue: PropTypes.shape({
            name: PropTypes.string,
            color: PropTypes.string,
          }),
        }).isRequired,
      }),
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};

TheaterPieces.defaultProps = {
  theme: 'light',
};

export default TheaterPieces;
