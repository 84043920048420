// Constants
import { IMAGE_FORMATS } from '../constants';

/**
 * Scoping image by its available format
 * format periority is as follows :
 * THUMBNAIL, SMALL, MEDIUM, LARGE, ORIGINAL
 * @returns {String} imageURL source
 */
const getImageByAvailableFormat = (image, forcedFormat) => {
  const { THUMBNAIL, SMALL, MEDIUM, LARGE, ORIGINAL } = IMAGE_FORMATS;
  if (forcedFormat) {
    return image[forcedFormat];
  }

  const availalbleFormats = Object.keys(image);
  if (availalbleFormats.includes(MEDIUM)) {
    return image[MEDIUM];
  }
  if (availalbleFormats.includes(SMALL)) {
    return image[SMALL];
  }
  if (availalbleFormats.includes(THUMBNAIL)) {
    return image[THUMBNAIL];
  }
  if (availalbleFormats.includes(LARGE)) {
    return image[LARGE];
  }
  return image[ORIGINAL];
};

export { getImageByAvailableFormat };
